import React, { Component } from "react";

class GNQRCode extends Component {
  render() {
    let { smallerQR } = this.props;
    const { listgenUserID } = this.props.agentData;
    let {
      homeOwnerURLID,
      homeOwnerURLQRCodePath,
      homeOwnerURL,
    } = this.props.hoData;

    if (listgenUserID === "101") {
      homeOwnerURL = "https://ourproperty.info/c12p";
      homeOwnerURLID = "c12p";
      homeOwnerURLQRCodePath =
        "https://lg-qr-code-images.s3.amazonaws.com/c12p.png";
    }
    let largerQRCode = false;
    if (listgenUserID === "100030") {
      largerQRCode = true;
    }
    let lowerQRCodeStyles = "";
    if (listgenUserID === "105805") {
      lowerQRCodeStyles = "lower-qr-code-2";
    } else if (listgenUserID === "105794") {
      lowerQRCodeStyles = "qr-code-top-345";
    } else if (
      listgenUserID === "104835" ||
      listgenUserID === "107019" ||
      listgenUserID === "105790"
    ) {
      lowerQRCodeStyles = "lower-qr-code-2";
    }
    return (
      <div
        className={`qr-code-section-v2 ${
          smallerQR && "smaller-qr-section-v2"
        } ${lowerQRCodeStyles}`}
      >
        <div className="qr-code-col1">
          <div
            className={`bubble-arrow-container ${
              smallerQR && "smaller-qr-arrow-img"
            } ${largerQRCode && "larger-qr-arrow-img"}`}
          >
            <img
              src="https://pma-coverphotos.s3.amazonaws.com/non-covers/DoubleArrow_4-QRcode_BluGrey-01.png"
              alt="Double Arrow"
            />
          </div>
        </div>
        <div className="qr-code-col2">
          <div className="qr-code-content-row">
            <div
              className={`bubble-qr-container ${
                smallerQR && "smaller-qr-img"
              } ${largerQRCode && "larger-qr-img"}`}
            >
              {listgenUserID === "100411" ? (
                <img
                  src="https://pma-coverphotos.s3.amazonaws.com/040323_Garret-MC_QRcode_500px.jpg"
                  className="bubble-qr-image"
                  alt="Garrett QR Code"
                />
              ) : (
                <img
                  src={homeOwnerURLQRCodePath}
                  className="bubble-qr-image"
                  alt="QR Code"
                />
              )}
            </div>
            <div className="qr-code-text">
              {listgenUserID === "100030" ? (
                <p>
                  Your Home Valuation at Your Fingertips:
                  <br /> Simply scan the QR code or go to
                  <a
                    href="https://www.randallrealty.com/valuation-form/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <span className="qr-code-link">
                      randallrealty.com/valuation-form
                    </span>
                  </a>
                </p>
              ) : listgenUserID === "100411" ? (
                <p>
                  Do you know what your home is worth? Simply scan the QR code
                  to the left or go to
                  <a
                    href="https://hmbt.co/DcY6Gq"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <span className="qr-code-link">https://hmbt.co/DcY6Gq</span>
                  </a>
                </p>
              ) : (
                <p>
                  Your Home Valuation at Your Fingertips:
                  <br /> Simply scan the QR code or go to
                  <a
                    href={homeOwnerURL}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <span className="qr-code-link">
                      OurProperty.info/{homeOwnerURLID}
                    </span>
                  </a>
                </p>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default GNQRCode;
